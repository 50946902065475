import React, { useState, useContext } from "react";
import Row from "../Components/Row";
import FileUploadColumn from "../Components/FileUploadColumn"
import TabNavBar from "../Components/TabNavBar";
import TabButton from "../Components/TabButton";
import TabContent from "../Components/TabContent";
import TabPane from "../Components/TabPane";
import Card from "../Components/Card";
import Column from "../Components/Column";
import Label from "../Components/Label";
import Button from "../Components/Button";
import Checkbox from "../Components/Checkbox";
import { GlobalContext } from "../GlobalContext";
import { uploadModel, uploadFormula } from "../Actions/FileUpload";
import { SFsmContext } from "./Context";


function SFSMFileUploadPage({ setModel, setFormula, parseModelFiles, parseFormulaFiles }) {

    const globalContext = useContext(GlobalContext);
    const sfsmContext = useContext(SFsmContext)

    const [state, setState] = useState({
        tab: 0, // 1: Model, 2: Harness, 3: Examples
        selectedModel: "",
        selectedFormula: ""
    });

    /*
     * Change handler for handle change events of 
     * selected Model. Delect all others: Only
     * one model can be selected. 
     */
    function onChangeModelCheckbox(cbName) {
        globalContext.sfsmModels.forEach((elem) => {
            if ( elem.name !== cbName.entry.name ) {
                elem.checked = false;
            } 
            else {
                elem.checked = true;
            }
        })

        // Update state
        setState({
            ...state,
            selectedModel: cbName.entry.name // very weired, but works
        })
    }

    /**
     * Change handler for selecting a model folder
     */
    function onChangeSelectModelFolder(event) {
        globalContext.fsmModels = []
        let filesOfFolder = [].slice.call(event.target.files); // to array
        parseModelFiles(filesOfFolder);
        setState({...state})
    }


    function onClickUploadModel() {
        // get here checked elem object from file parsing class
        globalContext.sfsmModels.forEach( (elem) => {
            if ( elem.checked ) {
                uploadModel(elem.uniqueName, 
                            elem.isFsmType,
                            elem.fsmFileContent, 
                            elem.stateFileContent,
                            elem.inFileContent,
                            elem.outFileContent,
                            elem.csvFileContent,
                            true,
                            setModel);
            }
        });
    }

    /**
     * Update the example model selected 
     */
    function updateExampleModelTo(selectedExampleModel) {
        setState({
            ...state
        })

        setModel(selectedExampleModel, "Using model '" + selectedExampleModel + "'")
    }

    /**
     * Change handler, if one clicked a formula file to upload
     */
    function onChangeFormulaCheckbox(cbName) {
        sfsmContext.formulas.forEach((elem) => {
            if ( elem.name !== cbName.entry.name ) {
                elem.checked = false;
            } 
            else {
                elem.checked = true;
            }
        })

        // Update state
        setState({
            ...state,
            selectedFormula: cbName.entry.name // very weired, but works
        })
    }

    /*
     * Update the current tab num in class state
     * to `tabNum` 
     */
    function updateTabStateTo(tabNum) {
        setState({
            ...state,
            tab: tabNum
        })
    }

    /*
     * Update the example model selected 
     */
    function updateExampleFormulaTo(selectedExampleFormula) {
        setState({
            ...state,
            exampleFormula: selectedExampleFormula
        })

        setFormula(selectedExampleFormula, "Using formula '" + selectedExampleFormula + "'")
    }

    /**
     * Change handler, if someone has selected a new formulas
     * directory from host computer
     */
    function onChangeSelectFormulasFolder(event) {
        let filesOfFolder = [].slice.call(event.target.files); // to array
        parseFormulaFiles(filesOfFolder);
    }

    /**
     * Callback of button to upload a formulas file.
     */
    function onClickUploadFormula() {
        // get here checked elem object from file parsing class
        sfsmContext.formulas.forEach( (elem) => {
            if ( elem.checked ) {
                uploadFormula(elem.uniqueName, elem.content, setFormula);
            }
        });
    }


    return (
        <> 
            <Row>
                <FileUploadColumn>
                    <h4>File Upload</h4>
                </FileUploadColumn>
            </Row>
            <Row>
                <FileUploadColumn>
                    <TabNavBar className="nav nav-tabs" id="sfsm-nav-tab-files">
                        <TabButton
                            className="nav-link active"
                            id="sfsm-nav-model-tab"
                            target="#sfsm-model-tab-pane"
                            ariaControl="sfsm-model-tab-pane"
                            onClick={ () => updateTabStateTo(1) }>
                            Model
                        </TabButton>
                        <TabButton
                            className="nav-link"
                            id="sfsm-nav-formula-tab"
                            target="#sfsm-formula-tab-pane"
                            ariaControl="sfsm-formula-tab-pane"
                            onClick={ () => updateTabStateTo(2) }>
                            Formulas
                        </TabButton>
                        <TabButton
                            className="nav-link"
                            id="sfsm-nav-example-tab"
                            target="#sfsm-examples-tab-pane"
                            ariaControl="sfsm-examples-tab-pane"
                            onClick={ () => updateTabStateTo(3) }>
                            Examples
                        </TabButton>
                    </TabNavBar>

                    <TabContent id="sfsm-rightTabContent">
                        <TabPane id="sfsm-model-tab-pane" className="tab-pane active">
                            {/* Select Model Folder */}
                            <Card headerName="Model-Folder">
                                {/* This is the list for selecting input folder */}
                                <Row>
                                    <input 
                                        type="file"
                                        className="form-control model-file-select" 
                                        id="sfsm-file_input_model_files" 
                                        onChange={ onChangeSelectModelFolder }
                                        webkitdirectory="true" 
                                        multiple />
                                </Row>
                                <Row>
                                    <Column className="col-8 select-model-form">
                                        Select Model
                                    </Column>
                                </Row>
                                <Row>
                                    {/* This is the list that shows the model-names */}
                                    <Column className="col-12">
                                        <div className="sidebar-offcanvas" id="sfsm-sidebar" role="navigation">
                                            <div className="well sidebar-nav">
                                                <div className="form-check nav2 form-inline" id="modelListSFsm" style={{height: '400px', marginLeft: '-40px'}}>
                                                    <table className="table table-sm table-hover" >
                                                        <tbody> 
                                                            {   
                                                                globalContext.sfsmModels.map((entry) =>
                                                                <tr key={ entry.name }>
                                                                    <td>
                                                                        <div 
                                                                            className="form-check model-list" 
                                                                            key={ entry.name }>
                                                                                <Checkbox 
                                                                                    className="model-checkbox" 
                                                                                    id={ entry.name } 
                                                                                    onChange={() => onChangeModelCheckbox( { entry } ) }
                                                                                    checked={ entry.checked ? true : false }>
                                                                                    </Checkbox>
                                                                                <Label 
                                                                                    className="checkbox-inline nav3">{ entry.name }
                                                                                </Label>
                                                                        </div>
                                                                    </td>
                                                                </tr>)
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Column> 
                                </Row>
                                <Row>
                                    <Column className="col-12">
                                        <Button
                                            id="SfsmUploadModelBtn"
                                            bootstrapClass="btn btn-primary bg-gradient"
                                            onClick={() => onClickUploadModel() }>
                                            Upload Model
                                        </Button>
                                    </Column>
                                </Row>
                            </Card>
                        </TabPane>


                        {/* Select Formula Folder */}
                        <TabPane id="sfsm-formula-tab-pane" className="tab-pane">
                            <Card headerName="Formulas-Folder">
                                {/* This is the list for selecting input folder */}
                                <Row>
                                    <input 
                                        type="file"
                                        className="form-control model-formula-select" 
                                        id="sfsm-file_input_formula_files" 
                                        onChange={ onChangeSelectFormulasFolder }
                                        webkitdirectory="true" 
                                        multiple />
                                </Row>
                                <Row>
                                    <Column className="col-8 select-formula-form">
                                        Select Formula
                                    </Column>
                                </Row>
                                <Row>
                                    {/* This is the list that shows the model-names */}
                                    <Column className="col-12">
                                        <div className="sidebar-offcanvas" id="sfsm-sidebar" role="navigation">
                                            <div className="well sidebar-nav">
                                                <div className="form-check nav2 form-inline" id="formulaListSFsm" style={{height: '400px', marginLeft: '-40px'}}>
                                                    <table className="table table-sm table-hover" >
                                                        <tbody> 
                                                            {   
                                                                sfsmContext.formulas.map((entry) =>
                                                                <tr key={ entry.name }>
                                                                    <td>
                                                                        <div className="form-check formula-list" key={ entry.name }>
                                                                                <Checkbox 
                                                                                    className="formula-checkbox" 
                                                                                    id={ entry.name } 
                                                                                    onChange={() => onChangeFormulaCheckbox( { entry } ) }
                                                                                    checked={ entry.checked ? true : false }>
                                                                                    </Checkbox>
                                                                                <Label 
                                                                                    className="checkbox-inline nav3">{ entry.name }
                                                                                </Label>
                                                                        </div>
                                                                    </td>
                                                                </tr>)
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </Column> 
                                </Row>
                                <Row>
                                    <Column className="col-12">
                                        <Button
                                            id="SfsmUploadFormulaBtn"
                                            bootstrapClass="btn btn-primary bg-gradient"
                                            onClick={() => onClickUploadFormula() }>
                                            Upload Formula
                                        </Button>
                                    </Column>
                                </Row>
                            </Card>
                        </TabPane>

                        <TabPane id="sfsm-examples-tab-pane" className="tab-pane">

                            {/* Example Models */}
                            <Card headerName="Example Models">
                                <Row>
                                    <Column className="col-12">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Use</th>
                                                    <th scope="col">Download</th>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>brake</td>
                                                    <td>
                                                        <Button
                                                            bootstrapClass="btn btn-success bg-gradient"
                                                            id="btnUseExampleModelSFsm"
                                                            onClick={() => updateExampleModelTo("brake") }>
                                                            Use
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <a 
                                                            className="btn btn-primary bg-gradient" 
                                                            type="button" 
                                                            href="resources/brake.zip">
                                                            Download
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>brake-restricted</td>
                                                    <td>
                                                        <Button
                                                            bootstrapClass="btn btn-success bg-gradient"
                                                            id="btnUseExampleModelSFsm"
                                                            onClick={() => updateExampleModelTo("brake-restricted") }>
                                                            Use
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <a 
                                                            className="btn btn-primary bg-gradient" 
                                                            type="button" 
                                                            href="resources/brake-restricted.zip">
                                                            Download
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Column>
                                </Row>
                            </Card>

                            {/* Example Formula Files */}
                            <Card headerName="Example Formulas">
                                <Row>
                                    <Column className="col-12">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Use</th>
                                                    <th scope="col">Download</th>
                                                </tr>
                                                <tr>
                                                    <th scope="row">1</th>
                                                    <td>brake-formulas</td>
                                                    <td>
                                                        <Button
                                                            bootstrapClass="btn btn-success bg-gradient"
                                                            id="btnUseExampleFormulaSFsm"
                                                            onClick={() => updateExampleFormulaTo("brake-ap") }>
                                                            Use
                                                        </Button>
                                                    </td>
                                                    <td>
                                                        <a 
                                                            className="btn btn-primary bg-gradient" 
                                                            type="button" 
                                                            href="resources/brake-ap.zip">
                                                            Download
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Column>
                                </Row>
                            </Card>

                        </TabPane>
                    </TabContent>
                </FileUploadColumn>
            </Row>
        </>
    );
}

export default SFSMFileUploadPage;
